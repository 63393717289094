@import "/src/data/styles.css";

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #181818;
}

::-webkit-scrollbar-thumb {
	background-color: #383636;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(0, 0, 0);
}

.page-content {
	background-color: rgb(15, 15, 15);
	position: relative;
}

.page-content:before,
.page-content:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: calc((100% - 1200px) / 2);
	background-color: #fafafa;
	border: 1px solid #f4f4f5;
}

.page-content:before {
	left: 0;
	z-index: 1;
}

.page-content:after {
	right: 0;
	z-index: 1;
}

.content-wrapper {
	max-width: 1000px;
	margin: 0 auto;
}

.title {
    color: var(--primary-color);
    font-family: var(--secondary-font);
    font-size: 45px;
    font-weight: 700;
    width: 70%;
    margin-bottom: 40px;
}

.subtitle {
	padding-top: 10px;
	color: var(--secondary-color);
	font-size: 16px;
	line-height: 28px;
	width: 70%;
	margin-top: 25px;
}

.page-footer {
	position: block;
}

.page-footer::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	border-top: 2px solid var(--tertiary-color);
}

@media (max-width: 1270px) {
	.page-content:before,
	.page-content:after {
		width: calc((100% - 90%) / 2);
	}

	.content-wrapper {
		max-width: 90%;
		padding-left: 10%;
		padding-right: 10%;
	}
}

@media (max-width: 1024px) {
	.page-content:before,
	.page-content:after {
		width: calc((100% - 950px) / 2);
	}

	.content-wrapper {
		max-width: 980px;
	}
}

@media (max-width: 800px) {
	.content-wrapper {
		max-width: 90%;
		margin: 0 auto;
		padding: 0 10px;
	}

	.page-content:before,
	.page-content:after {
		display: none;
	}

	.title {
		width: 100%;
	}

	.subtitle {
		width: 100%;
	}
}
.page-content:after, .page-content:before {
    background-color: #101010;
    border: 1px solid #262626;
}

.page-content {
    min-height: 100vh;
}

.page-footer {
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    left: 50%;
    max-width: 1000px;
    position: relative;
    right: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    margin-top: 60px;
}

.footer {
    position:relative;
}

.content-wrapper {
    min-height: 100vh;
}

.page-footer:before {
    top: 0;
}

.page-content:after, .page-content:before {
    background-color: #0f0f0f;
    border: 0px;
}

.page-content::before {
    z-index: 5;
    display: block;
    position: sticky;
    top: 0;
    width: 100%;
    height: 80px;
    border: none;
    background: linear-gradient(180deg, rgb(19 19 19) 0%, rgb(16 16 16 / 91%) 38%, rgb(14 14 14 / 72%) 69%, rgb(47 47 47 / 0%) 100%);
}

@media screen and (max-width: 1024px) {
    img.logo {
        clip-path: inset(0px 173px 0px 0px);
    }
}