.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
    display: flex;
    position: fixed;
    top: 4.8vh;
    z-index: 1000;
}

.contact-subtitle {
	width: 100% !important;
    font-size:18px;
    color:#fff;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
	min-height:77vh;
}


.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.contact-form-container form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

.contact-form-container form label {
    color: #fff;
    margin-bottom: 5px;
    font-family: var(--primary-font);
}

.contact-form-container form input {
    border-radius: 50px;
    height: 40px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 30px;
    padding: 0 20px;
    font-family: var(--primary-font);
    background-color:#1c1c1c;
    border:none;
    color:#fff;
}

.contact-form-container form textarea {
    border-radius: 25px;
    width: 100%;
    max-width: 800px;
    height: 400px;
    padding: 20px;
    font-family: var(--primary-font);
    background-color:#1c1c1c;
    border:none;
    color:#fff;
}

.contact-form-container form input[type="submit"] {
    max-width: 100px;
    margin-top: 20px;
    background-color: var(--link-color);
    font-weight:bold;
    border:none !important;
    transition: 0.5s;
    color:#000;
}
.contact-form-container form input[type="submit"]:hover,
.contact-form-container form input[type="submit"]:focus {
    background-color:#fff;
}

.contact-form-state {
	color:#fff;
}

p.error {
    color: red;
    position: relative;
    top: -40px;
}

span.error.message {
    color: red;
}