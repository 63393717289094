@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 380px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 80%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 40px;
	}

	.nav-item {
		font-weight: bold;
		font-size: 100%;
	}
}

li.nav-item {
    padding: 0 20px;
    padding: 3px 20px;
}

.nav-list {
    align-items: end;
    justify-content: flex-end;
    text-align: right !important;
    margin-top: 30px;
}

.nav-background {
    width: 100%;
    max-width: 1050px;
    border-radius: 10px;
    height: 80px;
}
.navbar {
    width: 100%;
}

.nav-background {
    background-color: transparent;
    box-shadow: none;
}

.nav-item a {
    color: #fff;
    text-decoration: none;
}

.nav-background {
    background-color: #161616c4 !important;
    filter: drop-shadow(5px 5px 10px #000000c4);
}

@media screen and (max-width: 1024px) {
	.nav-background {
		max-width: 90vw;
	}
}