@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
    margin-bottom: 60px;
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-title {
	width: 85% !important;
}

.homepage-subtitle {
	width: 85% !important;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 370px;
	height: 370px;
}

.homepage-image-wrapper {
	overflow: hidden;
	border-radius: 100%;
	transform: rotate(3deg);
}

.homepage-image-wrapper img {
	width: 100%;
}

.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
    display: flex;
    flex-wrap: wrap;
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
}

.homepage-article {
	padding-bottom: 5px;
}

.homepage-works {
	flex-basis: 500px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}

.homepage-skills{
    padding: 5rem 0 2.5rem 0;
}
.homepage-skills > .text{
    width: 100%;
    margin: 0;
    text-align: left;
    color: #000000;
    font-size: 16px;
}
.homepage-skills .row{
    margin: 1rem 0;
}
.homepage-skills .item{
    padding: 0.5rem 0;
    margin: 0.3rem 0;
}
.homepage-skills .item-text{
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    color: #464646;
    font-size: 14px;
    position: relative;
}
.homepage-skills .item-text span:last-child{
    position: absolute;
}
.homepage-skills .item-text span.w-100 {
    right: 0;
}
.homepage-skills .item-text .w-90{
    margin-left: 85%;
}
.homepage-skills .item-text .w-75{
    margin-left: 70%;
}
.homepage-skills .item-text .w-85{
    margin-left: 80%;
}
.homepage-skills .item-text .w-80{
    margin-left: 75%;
}
.homepage-skills .item-text .w-60{
    margin-left: 55%;
}
.homepage-skills .item-text .w-68{
    margin-left: 63%;
}
.progress{
    margin: 0.8rem 0 0;
    border-radius: 10px;
    height: 16px;
    width: 100%;
    background-color: black;
    overflow: hidden;
}
.progress-bar{
    background: var(--link-color);
    height: 100%;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.progress-bar.w-90{
    width: 90%;
}
.progress-bar.w-75{
    width: 75%;
}
.progress-bar.w-85{
    width: 85%;
}
.progress-bar.w-80{
    width: 80%;
}
.progress-bar.w-60{
    width: 60%;
}
.progress-bar.w-68{
    width: 68%;
}

.homepage-skills {
    max-width: 450px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 25px;
    margin-top: 25px;
    margin-left: 10px;
	height:fit-content;
}

.homepage-skills h2 {
    font-size: 14px;
    font-weight: normal !important;
    text-transform: capitalize;
    margin-top: 19px;
    color: #000;
}

.szh-accordion {
    z-index: 1;
    display: block;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: center;
    flex-direction: row;
}

.szh-accordion button {
    float: right;
    text-align: right;
    position: absolute;
    right: 0;
    background-color: transparent;
    border: none;
    content: '';
    top: 5px;
    /* display: none; */
    text-align: center;
    color: transparent;
}

.szh-accordion__item-content {
    color: #fff;
    max-width: 95%;
    font-size: 14px;
    margin-top: 14px;
}

.szh-accordion button::before {
    content: '>';
    color: #000000;
    display: block !important;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 30px;
    margin: auto;
    line-height: 30px;
    transition:0.3s;
}

.szh-accordion button:hover::before{
    background-color:#e9e599;
}

.homepage-skills h2 svg {
    margin-right: 15px;
}

.homepage-about-2 {
    display: inline-block;
    max-width: auto;
    padding: 0px 0px 0px 40px;
    width: 100%;
    max-width: 440px;
}

.homepage-about-2 h2 {
    font-size: 44px;
    font-weight: 700;
    color: #fff;
    font-family: var(--secondary-font);
}

.homepage-about-2 p {
    color: var(--secondary-color);
    font-size: 16px;
    line-height: 28px;
    margin-top: 25px;
    padding-top: 10px;
    width: 100%;
}

.homepage-about-3 {
    display: inline-block;
    max-width: auto;
    max-width: 400px;
    padding: 150px 40px 40px 40px;
    width: 100%;
}

.homepage-about-3 h2 {
    font-size: 44px;
    font-weight: 700;
    color: #fff;
    font-family: var(--secondary-font);
}

.homepage-about-3 p {
    color: var(--secondary-color);
    font-size: 16px;
    line-height: 28px;
    margin-top: 25px;
    padding-top: 10px;
    width: 100%;
}

.badges span.badge-opentowork {
    background-color: white;
    border-radius: 50px;
    padding: 5px 20px;
}

.badges span.badge-location {
    color:#fff;
    margin-left:30px;
    display:inline-block;
}

.cta h2 {
    margin-bottom: 0;
    font-weight:bold;
}

.cta .button {
    position: absolute;
    right: 40px;
    top: 3.5vh;
    background-color: #000;
    color: #fff;
    padding: 20px;
    border-radius: 20px;
    transition:0.3s;
    font-weight:bold;
}

.cta {
    position: relative;
    max-width: 800px;
    background-color: #fff;
    margin: 40px auto 0;
    padding: 20px 40px 40px 40px;
    border-radius: 25px;
}

.cta .button:hover, 
.cta .button:focus {
    background-color:var(--link-color);
}

@media screen and (max-width: 1024px) {
    .homepage-works {
        order:2;
        max-width: 500px;
        position: relative;
        width: auto;
        margin: auto;
    }
    .homepage-about-2 {
        order:1;
        padding: 0;
        max-width: unset;
    }
    .homepage-about-3 {
        order:3;
        padding: 0;
        max-width: unset;
    }
    .homepage-skills {
        left: 50%;
        order: 4;
        position: relative;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: auto;
        margin-left: 0;
    }
    .nav-background {
        max-width: 90vw;
    }
}

.szh-accordion .szh-accordion__item-btn {
    right: -20px;
}

@media screen and (max-width: 1024px) {
    .homepage-image-container {max-width: 240px;max-height: 240px;}
    .homepage-logo-container {
        padding-top: 0;
        margin-left: 20px;
    }
}

@media screen and ( max-width:1024px ) {
    .cta .button { position:relative; display:inline-block;margin:0 20px 40px 40px; }
}
    