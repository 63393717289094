@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
    display: flex;
    padding-bottom: 40px;
    position: relative;
    flex-wrap: wrap;
}

.work-image {
    border-radius: 50%;
    /* box-shadow: 0 7px 10px rgba(0, 0, 0, .25); */
    height: 30px;
    /* outline: 6px solid #fff; */
    width: 30px;
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: #fff;
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.work-duration {
    color: var(--tertiary-color);
    display: inline-block;
    padding-left: 25px;
    padding-top: 4px;
    font-size: 12px;
}

.szh-accordion {
    z-index: 100;
    display: block;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: center;
    flex-direction: row;
}

.szh-accordion button {
    float: right;
    text-align: right;
    position: absolute;
    right: 0;
}

.szh-accordion__item-content {
    color: #fff;
    max-width: 80%;
    font-size: 14px;
    margin-top: 14px;
}
